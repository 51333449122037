import { Row, Col, TitlePage, Strong, P } from '../../../styled/globalStyles';
import { Button, Table } from '../../../components';
import { useCallback, useEffect, useState } from 'react';
import { apresentacoes } from '../../../services/medicamentosRequests';
import { ProdutoResult } from '../produtos';
import { WarningBox } from '../components/warning_box';
interface ListProduto {
    id_produto: string
    tipo_produto: string
}

interface MedicamentosSelecionadosProps {
    produtos: ProdutoResult[]
    goBack: () => void
}
interface BulaItem {
    codigo: string
    titulo: string
    texto: string
}
interface Bula {
    bula_arquivo: string
    bula_itens: BulaItem[]
}

interface BulaComponentProps {
    bula: BulaItem
    index: number
}

interface Apresentation {
    apresentacao: string
    bula: Bula
    codigo_barras: string
    id_apresentacao: string
    id_laboratorio: string
    id_produto: string
    id_status: string
    id_tipolista: string
    id_tipopreco: string
    laboratorio: string
    ncm: string
    norma: string
    principio_ativo: string
    produto: string
    regms: string
    status: string
    tipo_produto: string
    tipo_produto_descricao: string
    tipolista: string
    tipopreco: string
}

export default function MedicamentosSelecionados({ produtos, goBack }: MedicamentosSelecionadosProps) {
    const [loading, setLoading] = useState(false)
    const [apresentations, setApresentations] = useState<Apresentation[]>([])
    const [produto, setProduto] = useState<ProdutoResult>()
    const [apresentationsSelect, setApresentationsSelect] = useState<Apresentation[]>([])
    const [apresentation, setApresentation] = useState<Apresentation>()

    const handleClickLine = useCallback(async (item: any) => {
        const list: Apresentation[] = []
        apresentations.forEach((i) => {
            if (i.id_produto === item.id_produto) {
                list.push(i)
            }
        });
        setProduto(item)
        setApresentation(null)
        setApresentationsSelect(list)
    }, [apresentations])

    const handleApresentation = useCallback(async () => {
        setLoading(true)
        const data: ListProduto[] = []
        produtos.forEach((item) => {
            data.push({ id_produto: item.id_produto, tipo_produto: item.tipo_produto })
        });
        try {
            const res = await apresentacoes({ lista_produtos: data })
            if (res?.status) {
                setApresentations(res?.data)
            }
        } catch (error) {
            console.log(error)
        }

        setLoading(false)

    }, [produtos])

    useEffect(() => {
        handleApresentation()
    }, [handleApresentation])

    useEffect(() => {
        if (apresentations.length > 0) {
            handleClickLine(produtos[0])
        }
    }, [apresentations, produtos, handleClickLine])

    const handleClickLineApresentation = async (item: any) => {
        setApresentation(item)
    }

    const columns = [
        {
            id: ({ id_produto }: any) => id_produto,
            name: 'Produto',
            selector: ({ produto }: any) => produto
        },
        {
            id: ({ id_produto }: any) => id_produto,
            name: 'Laboratório',
            selector: ({ laboratorio }: any) => laboratorio,
            className: 'text-center'
        },
    ];

    const columnsApresentation = [
        {
            id: ({ id_apresentacao }: any) => id_apresentacao,
            name: 'Apresentação do Produto',
            selector: ({ apresentacao }: any) => apresentacao
        },
        {
            id: ({ id_apresentacao }: any) => id_apresentacao,
            name: 'Cód. de Barras',
            selector: ({ codigo_barras }: any) => codigo_barras,
            className: 'text-center'
        },
        // {
        //     name: 'Tipo de lista',
        //     selector: ({ tipolista }: any) => tipolista,
        //     className: 'text-center'
        // },
        // {
        //     name: 'Tipo de preço',
        //     selector: ({ tipopreco }: any) => tipopreco,
        //     className: 'text-center'
        // },
    ];

    const BulaComponent = ({ bula, index }: BulaComponentProps) => {
        var html = bula.texto;
        var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";
        return <div>
            {/* <button style={{ padding: 5, margin: 3, border: 'solid 1px #DCD7D6' }} {...getToggleProps()}>
                {isExpanded ? `- ${bula.titulo}` : `+ ${bula.titulo}`}
            </button> */}
            {/* <section {...getCollapseProps()}>{text}</section> */}
            <section>{text}</section>
        </div>
    }

    if (loading) {
        return (<Row className="row mt-4 p-4">
            <Col className="col col-md-12">
                <br /><br /><br />
                <P style={{ textAlign: 'center' }}>Carregando...</P>
            </Col>
        </Row >)
    }

    return (
        <div >
            <Row className="row mt-4 p-4">
                <Col className="col col-md-6 d-flex align-items-center">
                    <TitlePage>Buscar Medicamentos</TitlePage>
                </Col>
                <Col className="col mt-4 col-md-6 d-flex justify-content-end">
                    <Button
                        label="Voltar para a Tela de Resultados"
                        variant="primary"
                        size="large"
                        onClick={goBack} />
                </Col>
            </Row>
            <Row className="row p-4">
                <Col className="col col-md-12">
                    <P>A busca dos <Strong>{produtos?.length}</Strong> produtos selecionados retornou <Strong>{apresentations.length}</Strong> apresentações</P>
                    <P>Selecione o produto abaixo para ver as apresentações:</P>
                </Col>

            </Row>
            <Row className="row px-4">
                <Col className="col col-md-12">
                    <Table
                        data={produtos}
                        columns={columns}
                        selectedItem={produto?.id_produto}
                        onClickLine={handleClickLine}
                        collection="produtos"
                    />
                    <br />
                </Col>

                {apresentationsSelect.length > 0 && <Col className="col col-md-12">
                    <Table
                        data={apresentationsSelect}
                        columns={columnsApresentation}
                        selectedItem={apresentation?.id_apresentacao}
                        onClickLine={handleClickLineApresentation}
                        collection="apresentacoes"
                    />
                </Col>}


                <Col className="col col-md-12 col-sm-12 col-xs-12">
                    <br />
                    <WarningBox />
                </Col>
            </Row>
            {apresentation && <div>
                <br />

                <Row className="row px-4">
                    <Col style={{ border: 'solid 1px black', padding: '20px', margin: '10px' }} className="col col-md-10">
                        <TitlePage>Dados do Produto:</TitlePage>
                        <br />
                        <P><Strong>Produto:</Strong> {apresentation?.produto}</P>
                        <P><Strong>Laboratório:</Strong> {apresentation?.laboratorio}</P>
                        <P><Strong>Princípio Ativo:</Strong> {apresentation?.principio_ativo}</P>
                        <P style={{ color: 'red' }}><Strong style={{ color: 'black' }}>Tipo de Produto:</Strong> {apresentation?.tipo_produto}</P>
                        <P><Strong>Subtipo de Produto:</Strong> </P>
                    </Col>
                </Row>
                {/* <Row className="row px-4">
                    <Col className="col col-md-12">
                        <br />
                        <TitlePage>Dados da Apresentação:</TitlePage>
                        <br />
                        <Row className="row">
                            <Col style={{ border: 'solid 1px black', padding: '20px', margin: '5px' }} className="col col-md-3">
                                <div >
                                    <P><Strong>Código:</Strong> {apresentation?.codigo_barras}</P>
                                    <P><Strong>Tipo de Lista:</Strong> {apresentation?.tipolista}</P>
                                </div>
                            </Col>
                            <Col style={{ border: 'solid 1px black', padding: '20px', margin: '5px' }} className="col col-md-3">
                                <div >

                                    <P><Strong>Apresentação:</Strong> {apresentation?.apresentacao}</P>
                                    <P><Strong>Tipo de Preço:</Strong> {apresentation?.tipopreco}</P>
                                    <P><Strong>Norma:</Strong> {apresentation?.norma}</P>
                                </div>
                            </Col>
                            <Col style={{ border: 'solid 1px black', padding: '20px', margin: '5px' }} className="col col-md-3">
                                <div >
                                    <P><Strong>Status:</Strong> {apresentation?.status}</P>
                                    <P><Strong>Reg. MS:</Strong> {apresentation?.regms}</P>
                                    <P><Strong>NCM:</Strong> {apresentation?.ncm}</P>
                                </div>


                            </Col>
                        </Row>
                    </Col>
                </Row> */}
                <Row className="row px-4">
                    <Col className="col col-md-12">
                        <br />
                        <TitlePage>Bula:</TitlePage>
                        <br />
                        <div>
                            {/* <button style={{ padding: 5, margin: 3, border: 'solid 1px #DCD7D6' }} {...getToggleProps()}>
                                {isExpanded ? `- Download PDF` : `+ Download PDF`}
                            </button>
                            <section {...getCollapseProps()}><a href={apresentation.bula.bula_arquivo} target="_blank" >Baixar Arquivo</a></section> */}
                            <section><a href={apresentation.bula.bula_arquivo} target="_blank" rel="noreferrer" >Baixar Arquivo</a></section>
                        </div>

                        {apresentation.bula.bula_itens.map((item, index) => <BulaComponent bula={item} index={index} />)}
                    </Col>
                </Row>
                <br />
            </div>}
        </div>
    )
}