export const getFirstName = (name: string) => {
  let firstName = name.split(" ");

  return firstName[0];
};

export const handleProfile = (profile: number) => {
  switch (profile) {
    case 1:
      return "Administrador";

    case 2:
      return "Doutor";

    case 3:
      return "Laboratório";

    case 4:
      return "Editor";

    case 5:
      return "Moderador";

    case 6:
      return "Representante";
  }
};

export const removeSpecialCharacters = (string: string) => {
  var er = /[^a-z0-9]/gi;
  string = string.replace(er, "");

  return string;
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const dateSql2Br = (date: any) => {
  if (date === null || isEmpty(date)) return null;

  var data = new Date(date),
    day = data.getDate().toString().padStart(2, "0"),
    month = (data.getMonth() + 1).toString().padStart(2, "0"),
    year = data.getFullYear();

  return day + "/" + month + "/" + year;
};

export const fullDateSql2Br = (date: any) => {
  if (date === null || isEmpty(date)) return null;

  var data = new Date(date),
    day = data.getDate().toString().padStart(2, "0"),
    month = (data.getMonth() + 1).toString().padStart(2, "0"),
    year = data.getFullYear(),
    hoursTime = data.getHours(),
    hoursMinutes = data.getMinutes().toString().padStart(2, "0");

  return `${day}/${month}/${year} às ${hoursTime}:${hoursMinutes}`;
};

export const isEmpty = (value: any) => {
  return !value || value.length === 0;
};

export const blobToBase64 = (url: any, callback: any) => {
  var xhr = new XMLHttpRequest();

  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };

  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

export const convertImage = async (image: any) => {
  if (!image) {
    return;
  }
  return new Promise((resolve) => {
    blobToBase64(image, function (dataUrl: any) {
      resolve(dataUrl);
    });
  });
};

export function moveScrollToButton(element: any) {
  if (element !== null) {
    const height = element.current.scrollHeight;
    element.current.scrollTo(height, height);
  }
}
// export function validatePhone(phone: string) {}

export function validatePhone(phone: string) {
  if (phone.length == 11) {
    if (phone.substring(2, 3) !== "9") {
      return false;
    }
    var sub = phone.substring(3, 11);

    switch (sub) {
      case "99999999":
        return false;
      case "88888888":
        return false;
      case "77777777":
        return false;
      case "66666666":
        return false;
      case "55555555":
        return false;
      case "44444444":
        return false;
      case "33333333":
        return false;
      case "22222222":
        return false;
      case "11111111":
        return false;
      case "00000000":
        return false;
    }
    return true;
  }
  return false;
}
