import http from "./api";


export const logAction = async (doctors_id: any, action: any, content_id: any) => {
    const { data } = await http.post('/logs/log', { doctors_id: doctors_id, action: action, content_id: content_id })
    return data;
}

export const logActionMedicamentos = async (body: any) => {
    const { data } = await http.post('/logs/drug_search', body)
    return data;
}