import { customStyles, TitleContainer} from './styled'
import { Row, P, H1 } from "../../styled/globalStyles";
import Modal from 'react-modal';
import { Button } from '..';

interface PropModal {
  isOpen: boolean
  title?: string
  description?: string
  button1?: string
  button1Func?: () => void
  button2?: string
  button2Func?: () => void
}

export const DialogAlert = ({isOpen, title, description, button1, button2, button1Func, button2Func} : PropModal) => {
  return (
    <Modal isOpen={isOpen} style={customStyles}>
      <TitleContainer>
        <H1>{title}</H1>
      </TitleContainer>
      <TitleContainer>
        <P>{description}</P>
      </TitleContainer>
        <Row className="row ">
          <Button 
          title={button1} 
          label={button1} 
          variant="primary" 
          size="medium"  
          onClick={()=>{if(button1Func){button1Func()}}}/>
    
        <Button 
          title={button2} 
          label={button2} 
          variant="light" 
          size="medium" 
          onClick={()=>{if(button2Func){button2Func()}}}/>
          </Row>
    </Modal>
  )
} 