import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";

const apiAxios = axios.create({
  // baseURL: "http://localhost/stratcon",
  baseURL: "https://app.doctorhub.com.br/api",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

apiAxios.interceptors.request.use(
  (config) => {
    if (config.url !== "auth/login") {
      config.headers["Token"] = window.localStorage.getItem(
        "doctor-hub-auth-token"
      );
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      if (typeof error !== "undefined") {
        if (error.response.status === 400) {
          toast.error(
            "Infelizmente não conseguimos contato com o servidor, tente novamente mais tarde.",
            { autoClose: false }
          );
        }
      }
    }

    return Promise.reject(error);
  }
);
class HttpApi {
  async post(url: string, data?: any, config?: AxiosRequestConfig) {
    return await apiAxios.post(url, data, config);
  }
  async get(url: string, config?: AxiosRequestConfig) {
    return await apiAxios.get(url, config);
  }
  async delete(url: string, config?: AxiosRequestConfig) {
    return await apiAxios.delete(url, config);
  }
}

var http = new HttpApi();

export default http;
