import { QueryClientProvider, QueryClient } from "react-query";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./styled/globalStyles";
import './App.scss';

import { theme } from "./styled/theme";
import './styled/global.css'
import Routes from './routes';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient(); 

const App = () => {
  return ( 
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <GlobalStyle />
        <Routes />
      </ThemeProvider> 
    </QueryClientProvider>
  )
}
 
export default App;