import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from '../context/auth/useAuth';

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any 
  children?: any;
} 

export default function PrivateRoute({ children, ...rest }: PrivateRouteProps ) {
  const { user } = useAuth();
    
  return (
    <Route
      {...rest}
      render={({ location }) =>
      user.email ? (
          children
          ) : (
            <Redirect 
              to={{ 
                pathname: "/login", 
                state: { from: location } 
            }}
          />
        )
      }
    /> 
  )
} 