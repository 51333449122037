import styled, { css } from "styled-components";

interface IModal {
  visible: boolean
}

const modifiers = {
  open: () => css``,
  close: () => css`
    display: none;
  `
}

export const Wrapper = styled.div<IModal>`
  ${({ visible })  => css` 
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;

    ${visible && modifiers.open()}
    ${!visible && modifiers.close()}
  `} 
`;