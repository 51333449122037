import styled from "styled-components";

export const WrapperTable = styled.div`
  background: #FFF;
  
 .box {
     position: relative;
     border-radius: 3px;
     background: #ffffff;
     border-top: 3px solid #d2d6de;
     margin-bottom: 20px;
     width: 100%;
     box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1)
 }

 .box-header.with-border {
     border-bottom: 1px solid #f4f4f4
 }

 .box-header {
     color: #444;
     display: block;
     padding: 10px;
     position: relative
 }

 .box-header:before,
 .box-body:before,
 .box-footer:before,
 .box-header:after,
 .box-body:after,
 .box-footer:after {
     content: "";
     display: table
 }

 .box-header .box-title {
     display: inline-block;
     font-size: 18px;
     margin: 0;
     line-height: 1
 }
 
 .box-header:after,
 .box-body:after,
 .box-footer:after {
     content: "";
     display: table
 }

 .box-body {
     border-top-left-radius: 0;
     border-top-right-radius: 0;
     border-bottom-right-radius: 3px;
     border-bottom-left-radius: 3px;
     padding: 10px
 }

 .box-body>.table {
     margin-bottom: 0
 }

 .table-bordered {
     border: 1px solid #f4f4f4
 }

 .table {
     width: 100%;
     max-width: 100%;
     margin-bottom: 20px
 }

 table {
     background-color: transparent
 }
 
 .box-footer {
     border-top-left-radius: 0;
     border-top-right-radius: 0;
     border-bottom-right-radius: 3px;
     border-bottom-left-radius: 3px;
     border-top: 1px solid #f4f4f4;
     padding: 10px;
     background-color: #fff
 }

 .pull-right {
     float: right !important
 }

 .pagination>li {
     display: inline
 }

 .pagination-sm>li:first-child>a,
 .pagination-sm>li:first-child>span {
     border-top-left-radius: 3px;
     border-bottom-left-radius: 3px
 }

 .pagination>li:first-child>a,
 .pagination>li:first-child>span {
     margin-left: 0;
     border-top-left-radius: 4px;
     border-bottom-left-radius: 4px
 }

 .pagination>li>button {
     background: #fafafa;
     color: #666
 }

 .pagination-sm>li>button,
 .pagination-sm>li>span {
     padding: 5px 10px;
     font-size: 12px;
     line-height: 1.5
 }

 .pagination>li>button,
 .pagination>li>span {
     position: relative;
     float: left;
     padding: 6px 12px;
     margin-left: -1px;
     line-height: 1.42857143;
     color: #337ab7;
     text-decoration: none;
     background-color: #fff;
     border: 1px solid #ddd
 }
`;