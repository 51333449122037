import styled from "styled-components";
import { theme } from "../../styled/theme";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.primary[0]}
`;