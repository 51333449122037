import http from "./api";

export const listaLaboratorios = async () => {
  try {
    const { data } = await http.get(`/medicaments/laboratory_list`);
    return data;
  } catch (e) {
    console.log({ e });
  }
};

export const procurarMedicamento = async (body: any) => {
  try {
    const { data } = await http.post(`/medicaments/search`, body);
    return data;
  } catch (e) {
    console.log({ e });
  }
};

export const apresentacoes = async (body: any) => {
  try {
    const { data } = await http.post(`/medicaments/presentations`, body);
    return data;
  } catch (e) {
    console.log({ e });
  }
};

export const getMedicos = async (
  representative: string,
  laboratories_id: string,
  keyterm: string,
  specialties_id: string
) => {
  try {
    const { data } = await http.get(
      `/notifications/doctors?representative=${representative}&laboratories_id=${laboratories_id}&keyterm=${keyterm}&specialties_id=${specialties_id}`
    );
    return data;
  } catch (e) {
    console.log({ e });
  }
};
